import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { HeaderAuraCard } from "../components/Headers/HeaderAuracard"
import { Banner } from "../components/Banners/HalvaCardVariation"
import { Category } from "../components/Category"
import { AuraForm } from "../components/NewForms/AuraForm"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import type { PageData } from "../interfaces/pageProps"
import type { City } from "../components/FormikForm/fields/CityField"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"

const FORM_TITLE =
  "Заполните форму и получите карту курьером или на стойке Совкомбанка в ТРЦ «Аура»"

const CITY: City = {
  value: "г. Новосибирск",
  label: "Новосибирск",
  region: "Новосибирская область",
}

const BENEFITS_TITLE = (
  <>
    Преимущества <br /> «ХАЛВА AURACARD»
  </>
)

const titleBanner = (
  <>
    <span style={{ color: "rgba(255, 78, 80, 1)" }}>ХАЛВА AURACARD —</span> одна карта для всего
  </>
)

const descBanner = (
  <>
    Спец.условия по Халве на покупки
    <br />в ТРЦ «АУРА». Оформите здесь!
  </>
)

export default function Page({ data }: PageProps<PageData>) {
  const { title, description, ligal, noIndex } = getPageData(data)

  return (
    <Layout description={description} title={title} noHeader noFooter noIndex={noIndex}>
      <HeaderAuraCard />
      <Banner variantImg="aura" title={titleBanner} description={descBanner} orderNum="1" />
      <FourAdvantagesPoints
        withTitle
        indent={false}
        variant="aura"
        title={BENEFITS_TITLE}
        orderNum="2"
      />
      <Category orderNum="3" />
      <AuraForm title={FORM_TITLE} default_city={CITY} orderNum="4" />
      <NewFooter ligal={ligal} isRedButton orderNum="5" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/halva-auracard/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
